<template>
    <div id="page-not-found">
        <!--<img alt="not-found" src="../../assets/img/7VE.gif">-->
        Page not found :(
    </div>
</template>

<script>
    export default {
        name: 'pageNotFound',
        components: {},
        methods: {}
    }
</script>

<style lang="less" scoped>
    #page-not-found {
        img {
            display: block;
            margin: 0 auto;
        }
    }
</style>
